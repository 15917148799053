.lead-font {
  font-family: Poiret One, Lucida Sans Unicode, Lucida Grande, sans-serif;
}

.body-font {
  font-family: Open Sans, sans-serif;
}

body {
  background: #fff;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
}

main {
  background-image: url("background.2c8a7f9f.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 720px;
  height: 480px;
  margin: auto;
  box-shadow: 0 0 10px #333;
}

.list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}

.icon {
  color: unset;
  cursor: pointer;
  text-decoration: none;
}

.launcher-item .icon {
  font-size: 3em;
}

.launcher-item .icon:hover {
  text-shadow: 0 0 1px #000;
}

.launcher {
  border: 1px solid #ddd;
  width: 170px;
  margin-left: 100px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.launcher .launcher-item {
  margin: 10px;
}

.launcher .launcher-title {
  text-align: center;
  background: #fff;
  border: 1px solid #fff;
  border-bottom-color: #ddd;
  margin: 0;
  padding: 8px;
  font-family: Poiret One, Lucida Sans Unicode, Lucida Grande, sans-serif;
  display: block;
}

.launcher .launcher-container {
  background: #fff;
  border: 1px solid #fff;
  border-radius: 0 0 4px 4px;
  flex-flow: wrap;
  place-content: space-around;
  align-items: center;
  padding: 10px;
  display: flex;
}

.launcher:hover .launcher-title {
  color: #033a86;
}

#canumeet-emfa-container {
  width: auto !important;
}

@media (width <= 720px) {
  section {
    background-size: cover;
  }

  .launcher {
    margin-left: 20px;
  }
}

@viewport {
  zoom: 1;
  width: device-width;
}
/*# sourceMappingURL=index.bd0e0b41.css.map */
