.lead-font {
    font-family: 'Poiret One', 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
}

.body-font {
    font-family: 'Open Sans', sans-serif;
}

.vertically-center(@position: relative) {
    position: @position;
    top: 50%;
    transform: translateY(-50%);
}

body {
    position: relative;
    display: flex;
    height: 100vh;
    margin: 0;
    padding: 0;
    background: white;
}

main {
    width: 720px;
    height: 480px;
    margin: auto;
    background-image: url('assets/images/background.jpg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 0 10px #333;
}

.list-unstyled {
    list-style: none;
    margin: 0;
    padding: 0;
}

.icon {
    color: unset;
    text-decoration: none;
    cursor: pointer;

    .launcher-item & {
        font-size: 3em;

        &:hover {
            text-shadow: 0 0 1px black;
        }
    }
}

.launcher {
    .vertically-center(absolute);

    margin-left: 100px;
    width: 170px;
    border: 1px solid #DDD;

    .launcher-item {
        margin: 10px;
    }

    .launcher-title {
        .lead-font;
        display: block;
        text-align: center;
        margin: 0;
        padding: 8px;
        background: white;
        border: 1px solid white;
        border-bottom: 1px solid #DDD;
    }

    .launcher-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        align-content: space-around;

        padding: 10px;
        background: white;
        background-color: white;
        border: 1px solid white;
        border-radius: 0 0 4px 4px;
    }

    &:hover {
        .launcher-title {
            color: rgb(3, 58, 134);
        }
    }
}

#canumeet-emfa-container {
    width: auto !important;
}

@media (max-width: 720px) {
    section {
        background-size: cover;
    }

    .launcher {
        margin-left: 20px;
    }
}

@viewport {
    zoom: 1.0;
    width: device-width;
}
